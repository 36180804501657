<template>
  <div class="DialogReservacionPendientePago">
    <v-dialog
      v-model="showDialog"
      width="350"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="_handleClose"
          />
          <v-btn
            class="accent"
            v-text="'Aceptar y reservar'"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "DialogReservacionPendientePago",
  data() {
    return {
      showDialog: false
    };
  },
  methods: {
    _handleClose() {
      this.showDialog = false;
      this.$emit("closed");
    }
  }
};
</script>