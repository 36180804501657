<template>
  <div>
    <v-btn
      block
      large
      class="primary"
      v-if="onlyPaymentButton"
      v-text="'Pagar ahora'"
      @click="showDialogReservacionPendientePago=true"
      :disabled="cargando"
      :loading="cargando"
    />
    <v-card-actions
      class="card_no_padding"
      v-else
    >
      <v-spacer />
      <v-btn
        text
        v-text="'Regresar'"
        @click="$router.push('/empresas/ambulancia')"
        :disabled="cargando"
        :loading="cargando"
      />
      <v-btn
        class="primary"
        depressed
        v-text="'Reservar'"
        @click="_guardarReservacion(false)"
        :disabled="cargando"
        :loading="cargando"
      />
      <!-- <v-btn
        v-if="showActions"
        class="accent"
        v-text="'Reservar y pagar'"
        @click="_guardarReservacion(true)"
      /> -->
    </v-card-actions>
    <v-dialog
      v-model="showDialogReservacionPendientePago"
      width="350"
      persistent
    >
      <v-card>
        <v-card-title class="headline">Importe a cubrir: {{$nF(total)}}</v-card-title>
        <v-card-text class="mt12">
          <PayPalCheckout
            v-if="showPayPalButton"
            class="mb16"
            :id="idEva"
            :amount="total"
            @authorized="_savePago('authorized',$event)"
            @completed="_savePago('completed',$event)"
            @cancelled="_pagoCancelled"
          />
          <v-btn
            v-if="!onlyPaypal"
            block
            outlined
            :to="'/ambulancia/detalle/'+idEva"
            @click="showDialogReservacionPendientePago=false"
          >Pagar en la unidad médica</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!onlyPaymentButton"
            text
            :to="'/ambulancia/detalle/'+idEva"
            v-text="'Cancelar'"
            :disabled="cargando"
            :loading="cargando"
          />
          <v-btn
            v-else
            text
            @click="showDialogReservacionPendientePago = false"
            v-text="'Cancelar'"
            :disabled="cargando"
            :loading="cargando"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms";
import DialogReservacionPendientePago from "@/components/dialogs/DialogReservacionPendientePago.vue";
import PayPalCheckout from "@/components/paypal/PayPalCheckout.vue";
export default {
  name: "AmbulanciasReservarAcciones",
  mixins: [forms],
  props: {
    onlyPaymentButton: Boolean,
    tipo: String,
    showActions: [Boolean, String],
    total: [String, Number],
    eva: [Object, String],
    data: Object,
    onlyPaypal: Boolean,
  },
  components: {
    DialogReservacionPendientePago,
    PayPalCheckout,
  },
  data() {
    return {
      showDialogReservacionPendientePago: false,
      showPayPalButton: false,
      cargando: false,
      idEva: null,
    };
  },
  methods: {
    async _guardarReservacion(saveAndPay = false) {
      this.cargando = true;
      try {
        const { status, body } = await this.$http.post(
          "ambulancias/reservar/" + this.tipo,
          {
            eva: this.eva,
            data: this.data,
            payment: saveAndPay,
          },
          this.$headers
        );
        if ((status === 201 || status === 200) && "id" in body) {
          let msg = "";
          this.idEva = body.id_eva;
          if (saveAndPay && body.estatus === "incompleto") {
            msg =
              "El evento ha sido reservado. En unos segundos verás la ventana para el pago en línea.";
            this.showDialogReservacionPendientePago = true;
          } else {
            msg = "El evento ha sido reservado.";
            if (body.estatus != "completo") {
              msg =
                msg +
                " Recuerda es necesario cubrir el importe 48 horas antes del evento.";
            }
            this.$router.push("/empresas/ambulancia/detalle/" + body.id);
          }
          this.$emit("msg", msg);
        } else {
          this.$emit("msg", body.error);
        }
      } catch (err) {
        this.$emit("httpError", err);
      }
    },
    _savePago(estatus, pagoData) {
      this.$http
        .post(
          "abonos/ambulancias",
          {
            idEva: this.idEva,
            estatus: estatus,
            datapp: pagoData,
          },
          this.$headers
        )
        .then(
          (res) => {
            if (res.status != 201) {
              this.$emit("httpError", res);
            } else if (
              (res.status === 201 || res.status === 200) &&
              "msg" in res.body &&
              estatus === "completed"
            ) {
              this.$emit("msg", res.body.msg);
              if (this.onlyPaymentButton) {
                this.$emit("refresh", true);
              } else {
                this.$router.push("/empresas/ambulancia/detalle/" + this.idEva);
              }
            }
          },
          (err) => {
            this.$emit("httpError", res);
          }
        );
    },
    _pagoCancelled() {},
  },
  watch: {
    showDialogReservacionPendientePago(a) {
      if (a) {
        this.showPayPalButton = false;
        setTimeout(
          function (scope) {
            scope.showPayPalButton = true;
          },
          50,
          this
        );
      } else {
        this.showPayPalButton = false;
      }
    },
    eva(a) {
      this.idEva = a;
    },
  },
  created() {
    if (this.eva) this.idEva = this.eva;
  },
};
</script>