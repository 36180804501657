<template>
  <div class="post-header item is-hero no-image page">
    <v-btn
      small
      text
      v-if="goTo"
      @click="$router.push(goTo)"
    >
      <v-icon
        small
        style="margin-right:8px"
        v-text="'arrow_back'"
      />
      {{goText}}
    </v-btn>
    <div class="item-content">
      <h1
        class="item-title"
        v-text="title"
      />
      <div
        class="global-meta"
        v-if="subtitle"
        v-text="subtitle"
      >
      </div>
      <hr>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderTitle",
  props: {
    title: String,
    subtitle: String,
    goTo: String,
    goText: String,
  },
};
</script>