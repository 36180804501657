<template>
  <section class="AmbulanciasDetalle empresas__inner container mt24 mb24">
    <ButtonBackHome
      to="/empresas/ambulancias"
      text="Regresar a mis reservaciones"
    />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Información de tu reservación</h2>
    </header>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <div
          class="clave_reservacion"
          style="min-width:100%"
        >
          <div class="clave_reservacion-value">
            <strong class="upper">{{evento.id.substr(0,5)}}</strong>
          </div>
          <div class="clave_reservacion-text">Clave de reservación</div>
        </div>
      </v-col>
      <v-col
        sm="12"
        md="9"
        v-if="evento.estatus_cgo === 'incompleto'"
      >
        <div
          class="pagar_reservacion"
          v-if="isAbleToPay"
        >
          <span v-html="mensajeEstatus.largo" />
        </div>
        <div
          class="pagar_reservacion cancelada"
          v-if="!isAbleToPay"
        >
          <span v-html="mensajeEstatus.largo" />
        </div>
      </v-col>
    </v-row>

    <!-- <div v-if="evento.estatus_cgo === 'incompleto' && !isAbleToPay">
      <v-alert
        :type="isAbleToPay ? 'warning' :'error'"
        :style="'color:'+(isAbleToPay?  'black':'white')"
        v-html="mensajeEstatus.largo"
      />
      <br>
    </div> -->
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          :loading="cargando"
          flat
          outlined
        >
          <v-card-title v-text="'Información de tu reservación'" />
          <v-skeleton-loader
            v-if="cargando"
            :loading="cargando"
            type="list-item-three-line"
          />
          <v-list
            v-else
            dense
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-text="'grain'" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="upper"
                  v-text="evento.id.substr(0,5)"
                />
                <v-list-item-subtitle v-text="'Clave de reservación'" />
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-text="'style'" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="upper"
                  v-text="evento.tipo_eva"
                />
                <v-list-item-subtitle v-text="'Tipo de servicio'" />
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-text="'today'" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="upper nowrap"
                  v-text="_formatFecha(evento.fecha_inicio_eva+' '+evento.hora_inicio_eva)"
                />
                <v-list-item-subtitle v-text="'Fecha programada del servicio'" />
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-text="'place'" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="upper nowrap"
                  v-text="evento.lugar_inicio_eva.description"
                />
                <v-list-item-subtitle v-text="'Lugar de origen'" />
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <div v-if="evento.tipo_eva !='servicio especial'">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'pin_drop'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="upper nowrap"
                    v-text="evento.lugar_termino_eva.description"
                  />
                  <v-list-item-subtitle v-text="'Lugar de destino'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </div>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-text="'attach_money'" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$nF(evento.importe_cgo)" />
                <v-list-item-subtitle v-text="'Precio del servicio'" />
              </v-list-item-content>
            </v-list-item>
            <div v-if="evento.estatus_cgo ==='completo'">
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'description'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="'Folio: '+evento.id_cgo.padStart(8, '0')" />
                  <v-list-item-title v-text="'WebID: '+evento.webid.substr(0,6).toUpperCase()" />
                  <v-list-item-subtitle v-text="'Datos para solicitar tu factura'" />
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="!cargando"
      >
        <v-card outlined>
          <v-card-title>Requerimientos</v-card-title>
          <v-list dense>
            <div v-if="evento.tipo_eva ==='servicio especial'">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'local_shipping'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="evento.detalle.cant_ambulancias_ese" />
                  <v-list-item-subtitle v-text="'Ambulancias requeridas'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'group'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="evento.detalle.cant_paramedicos_ese" />
                  <v-list-item-subtitle v-text="'Paramédicos requeridos'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'access_time'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="evento.detalle.horas_ese + ' hora'+(parseInt(evento.detalle.horas_ese)==1?'':'s')" />
                  <v-list-item-subtitle v-text="'Duración del evento'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'account_circle'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="upper"
                    v-text="evento.detalle.responsable_ese"
                  />
                  <v-list-item-subtitle v-text="'Responsable'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'call'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="evento.detalle.contacto_ese" />
                  <v-list-item-subtitle v-text="'Teléfono de contacto'" />
                </v-list-item-content>
              </v-list-item>
            </div>
            <div v-if="evento.tipo_eva ==='traslado foraneo'">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'place'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="upper"
                    v-text="evento.detalle.paciente_etf"
                  />
                  <v-list-item-subtitle v-text="'Paciente'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'place'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="upper"
                    v-text="evento.detalle.condicion_etf"
                  />
                  <v-list-item-subtitle v-text="'Condición del paciente'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'place'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="upper"
                    v-text="_arrToString(evento.detalle.requerimientos_etf)"
                  />
                  <v-list-item-subtitle v-text="'Requerimientos'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'place'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="evento.detalle.distancia_etf +' kms'" />
                  <v-list-item-subtitle v-text="'Distancia'" />
                </v-list-item-content>
              </v-list-item>
            </div>
            <div v-if="evento.tipo_eva ==='traslado local'">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'place'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="upper"
                    v-text="evento.detalle.tipo_etl"
                  />
                  <v-list-item-subtitle v-text="'Tipo de traslado'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'place'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="upper"
                    v-text="evento.detalle.paciente_etl"
                  />
                  <v-list-item-subtitle v-text="'Paciente'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'place'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="upper"
                    v-text="evento.detalle.condicion_etl"
                  />
                  <v-list-item-subtitle v-text="'Condicion del paciente'" />
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-text="'place'" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="upper"
                    v-text="_arrToString(evento.detalle.requerimientos_etl)"
                  />
                  <v-list-item-subtitle v-text="'Requerimientos'" />
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="evento.estatus_cgo ==='completo'">
      <v-btn
        dark
        class="primary mt24"
        outlined
        block
        @click="_reenviarCartaConfirmacion"
      >
        <v-icon v-text="'mail'" /> Reenviar carta de confirmación
      </v-btn>
      <br><br>
    </div>
    <div
      v-if="evento.estatus_cgo === 'incompleto'"
      class="mt16 mb24"
    >
      <!-- <AmbulanciasReservarAcciones
        onlyPaymentButton
        onlyPaypal
        showActions
        :total="evento.importe_cgo"
        :eva="evento.id_eva"
        @msg="$emit('msg',$event)"
        @httpError="$emit('httpError',$event)"
        @refresh="_getReservacion"
      /><br><br> -->
      <!-- <v-alert
        class="mt24"
        type="info"
        v-text="'Recuerda que puedes pagar tambien en las bases médicas presentando el folio de tu reservación'"
      /> --><br>
      <v-alert
        class="mt24"
        :type="isAbleToPay ? 'warning' :'error'"
        :style="'color:'+(isAbleToPay?  'black':'white')"
        v-html="mensajeEstatus.largo"
      />

    </div>
  </section>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import HeaderTitle from "@/components/header/HeaderTitle.vue";
import AmbulanciasReservarAcciones from "@/components/ambulancias/AmbulanciasReservarAcciones";
export default {
  name: "AmbulanciasDetalle",
  mixins: [standard, forms],
  components: {
    AmbulanciasReservarAcciones,
    HeaderTitle,
  },
  data() {
    return {
      name: "",
      cargando: true,
      enviandoCarta: false,
      showContactsDialog: false,
      transition: "fade-transition",
      configuracion: {
        cancelacion_falta_pago_cose: null,
        cancelacion_falta_pago_cotl: null,
        cancelacion_falta_pago_cotf: null,
      },
      evento: {
        created_at_eva: null,
        detalle: {},
        donador: {},
        estatus_cgo: null,
        fecha_cgo: null,
        fecha_inicio_eva: null,
        fecha_termino_eva: null,
        hora_inicio_eva: null,
        hora_termino_eva: null,
        id: "     ",
        id_cgo: null,
        id_cgo_eva: null,
        id_cli_cgo: null,
        id_cpa_cgo: null,
        id_don_cgo: null,
        id_don_eva: null,
        id_eva: null,
        id_pac_cgo: null,
        id_usr_cgo: null,
        importe_cgo: null,
        lugar_inicio_eva: {},
        lugar_inicio_notas_eva: null,
        lugar_termino_eva: {},
        lugar_termino_notas_eva: null,
        tipo_eva: null,
        updated_at_eva: null,
      },
      limites: {
        cancelacion_falta_pago_cose: null,
        cancelacion_falta_pago_cotl: null,
        cancelacion_falta_pago_cotf: null,
      },
      mensajeEstatus: {
        corto: null,
        largo: null,
      },
      isAbleToPay: true,
    };
  },
  methods: {
    _getReservacion() {
      if ("idEva" in this.$route.params) {
        this._getThings("reservacion/" + this.$route.params.idEva, "evento");
      } else {
        this.$emit("msg", "No se encontró la reservación");
        this.$router.push("/empresas/ambulancias");
      }
    },
    _formatFecha(fecha) {
      return this.$Datetime
        .fromSQL(fecha)
        .setLocale("es-Mx")
        .toFormat("EEEE' 'd' de 'MMMM, yyyy 'A LAS 't a");
    },
    _arrToString(arr) {
      try {
        return JSON.parse(arr).join(", ");
      } catch (err) {
        return "Nigún requerimiento indicado";
      }
    },
    async _reenviarCartaConfirmacion() {
      this.enviandoCarta = true;
      try {
        const { status, body } = await this.$http.get(
          "reservacion/" + this.$route.params.idEva + "/reenviarcarta",
          this.$headers
        );
        if (status === 200 && "msg" in body) {
          this.enviandoCarta = false;
          this.$emit("msg", body.msg);
        } else {
          this.$emit("error", body.error);
        }
      } catch (err) {
        this.enviandoCarta = false;
        this.$emit("httpError", err);
      }
    },
    _aunDebePagar(
      whereToAns = "short",
      tipo_eva = null,
      estatus_cgo = null,
      fecha_eva = null
    ) {
      let estatus = estatus_cgo ? estatus_cgo : this.evento.estatus_cgo;
      if (tipo_eva && estatus === "incompleto") {
        let fecha = fecha_eva
          ? fecha_eva
          : this.evento.fecha_inicio_eva + " " + this.evento.hora_inicio_eva;
        let horas = 0;
        switch (tipo_eva) {
          case "servicio especial":
            horas = this.limites.cancelacion_falta_pago_cose;
            break;
          case "traslado foraneo":
            horas = this.limites.cancelacion_falta_pago_cotf;
            break;
          case "traslado local":
            horas = this.limites.cancelacion_falta_pago_cotl;
            break;
        }
        const diff = this.$Interval
          .fromDateTimes(this.$Datetime.now(), this.$Datetime.fromSQL(fecha))
          .length("hours");
        var c = diff > horas;
        if (whereToAns === "short") {
          return c ? "Pendiente de pago" : "Cancelado por falta de pago";
        } else {
          this.isAbleToPay = c;
          this.mensajeEstatus.corto = c
            ? "Pendiente de pago"
            : "Cancelado por falta de pago";
          //Tu reservación se encuentra pendiente de pago. <strong>Recuerda que el pago debe ser realizado con
          const reff = this.evento.id.substr(0, 5);
          if (tipo_eva === "servicio especial") {
            this.mensajeEstatus.largo = c
              ? "<p>Acude a tu unidad médica más cercana y presenta tu clave de reservación <strong class='upper'>" +
                reff +
                "</strong> para que te reciban tu pago y en ese momento se enviará la carta para realizar el trámite con protección civil</p>Si deseas realizar tu pago vía transferencia bancaria estos son nuestros datos bancarios. Banco: BBVA, Cuenta: 0481502107, Clabe interbancaria: 012150004815021079, Referencia: <strong class='upper'/>" +
                reff +
                "</strong>"
              : "Tu reservación no fue cubierta con " +
                horas +
                " horas de anticipación por lo cual no podemos atender tu solicitud";
          } else {
            this.isAbleToPay = 100;
            this.mensajeEstatus.largo = c
              ? "<p>Puedes realizar el pago en tu unidad médica de Cruz Roja más cercana o con los paramédicos que te asistan en tu traslado presentando <strong class='upper'>" +
                reff +
                "</strong> como tu clave de reservación.</p>Si deseas realizar tu pago vía transferencia bancaria estos son nuestros datos bancarios. Banco: BBVA, Cuenta: 0481502107, Clabe interbancaria: 012150004815021079, Referencia: <strong class='upper'/>" +
                reff +
                "</strong>"
              : "Tu reservación no fue cubierta con " +
                horas +
                " horas de anticipación por lo cual no podemos atender tu solicitud";
          }
          return true;
        }
      } else {
        this.isAbleToPay = true;
        this.mensajeEstatus.corto = null;
        this.mensajeEstatus.largo = null;
        return false;
      }
    },
    _getConfigHoras() {
      this._getThings("ambulancias/configuracion/limitehoras", "limites");
    },
  },
  created() {
    this._getConfigHoras();
    this._getReservacion();
  },
  watch: {
    reservacion() {
      this._aunDebePagar("long", this.evento.tipo_eva);
    },
    evento() {
      this._aunDebePagar("long", this.evento.tipo_eva);
    },
    limites() {
      this._aunDebePagar("long", this.evento.tipo_eva);
    },
  },
};
</script>
<style lang="scss">
</style>