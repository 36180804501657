<template>
  <PayPal
    :amount="amount"
    currency="MXN"
    :client="paypal.client"
    locale="es_MX"
    :buttonStyle="paypal.buttonStyle"
    :env="paypal.env"
    :braintree="paypal.braintree"
    @payment-authorized="$emit('authorized',$event)"
    @payment-completed="$emit('completed',$event)"
    @payment-cancelled="$emit('cancelled',$event)"
  />
</template>
<script>
import PayPal from "vue-paypal-checkout";
export default {
  name: "PayPalCheckout",
  components: { PayPal },
  props: {
    id: String,
    amount: String,
  },
  data() {
    return {
      paypal: {
        amount: "0",
        braintree: window.braintree,
        buttonStyle: {
          label: "checkout",
          size: "responsive",
          shape: "rect",
          color: "black",
        },
        client: {
          sandbox: process.env.VUE_APP_PP_CLIENT_ID_SANDBOX,
          production: process.env.VUE_APP_PP_CIENTT_ID_PRODUCTION,
        },
        env: process.env.VUE_APP_PP_ENV,
        experience: {
          input_fields: {
            no_shipping: 1,
          },
        },
        id: "",
      },
    };
  },
  methods: {
    _handleProps() {
      this.paypal.amount = this.amount;
      this.paypal.id = this.id;
    },
  },
  created() {
    this._handleProps();
  },
  watch: {
    amount: {
      immediate: true,
      deep: true,
      handler: "_handleProps",
    },
    id: {
      immediate: true,
      deep: true,
      handler: "_handleProps",
    },
  },
};
</script>